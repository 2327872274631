@use '../abstracts' as *;

/* --- inner --- */
.inner {
  width: 90%;
  max-width: 1080px;
  margin-left: auto;
  margin-right: auto;
  @include mq(sp) {
    width: 86%;
  }
}
.inner-sm { max-width: 880px; }
.inner-lg { max-width: 1440px; }
.inner-1200 { max-width: 1200px; }
.inner-1380 { max-width: 1380px; }
.inner-1720 { max-width: 1720px; }

.inner-xl { 
  max-width: calc(100% - 112px);
  @include mq(med)  {
    max-width: 90%;
  }
  @include mq(sp) {
    max-width: 86%;
  }
}