@use '../abstracts' as *;

/* btn
********************************************** */
*:not(.flex) > .btn:not(:last-child) {
  margin-bottom: 8px;
}
.btn a {
  position: relative;
  z-index: 1;
  display: flex;
  @include center-flex;
  padding: 14px 40px;
  width: 100%;
  height: 100%;
  max-width: 240px;
  font-size: rem(20);
  font-family: var(--font-en);
  font-weight: 700;
  line-height: 1.4;
  color: var(--clr-blk3);
  border: 1px solid var(--clr-blk3);
  text-align: center;
  @include mq(sp) {
    width: 260px;
    @include auto-margin;
    padding: 14px 24px;
  }
}  
.flex > .btn a { max-width: 100%; }
.btn.txt-ctr a {
  @include auto-margin;
}
.btn a:not([target="_blank"])::before,
.btn a:not([href$=".pdf"])::before,
.btn a:not([href$=".jpg"])::before,
.btn a:not([href$=".png"])::before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 28px;
  height: 1px;
  right: -8px;
  background-color: var(--clr-blk3);
  transition: var(--transit-default, all .4s ease-out);
}
.btn a:hover {
  color: var(--clr-wht);
  background-color: var(--clr-main);
  border: 1px solid var(--clr-main);
  &::before {
    right: -14px;
  }
}
.btn .icon-svg {
  fill: var(--clr-blk3);
  margin-left: 8px;
  transition: var(--transit-default, all .4s ease-out);
}
.btn a:hover .icon-svg {
  fill: var(--clr-wht);
}
.btn.btn-next_page {
  text-align: center;
}
.btn.btn-next_page a {
  display: inline-block;
  width: auto;
  max-width: inherit;
  padding: 14px 56px;
}

/* --- btn-wh --- */
.btn-wh a {
  color: var(--clr-wht);
  border-color: var(--clr-wht);
}
.btn-wh.btn-wh a::before {
  background-color: var(--clr-wht);
}
.btn-wh a:hover {
  color: var(--clr-blk3);
  border-color: var(--clr-wht);
  background-color: var(--clr-wht);
}
.btn.btn-wh .icon-svg {
  fill: var(--clr-wht);
}
.btn.btn-wh a:hover .icon-svg {
  fill: var(--clr-blk3);
}
@include mq(sp) {
  .flex > .btn {
    margin-bottom: 8px !important;
  }
}
