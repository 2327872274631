@use '../abstracts' as *;

/* lps_parts--slide
********************************************** */
  .lps_parts--slide .img img {
    display: inline-block;
  }
  .lps_parts--slide .img + .text {
    margin-top: 24px;
    padding-left: 24px;
    padding-right: 24px;
    @include mq(med) {
      margin-top: 16px;
    }
  }
  .lps_parts--slide .slick-list {
    margin-bottom: 32px;
  }
  .lps_parts--slide .slick-arrow {
    position: absolute;
    top: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 100%;
    text-decoration: none;
    @include mq(med) {
      width: 56px;
    }
    @include mq(sp) {
      width: 40px;
    }
  }
  .lps_parts--slide .slick-prev { 
    left: -60px;
    @include mq(med) {
      left: 0;
    }
  }
  .lps_parts--slide .slick-next { 
    right: -60px; 
    @include mq(med) {
      right: 0;
    }
  }
  .lps_parts--slide .slick-dots {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    @include mq(med) {
      position: relative;
      bottom: 4px;
    }
  }
  .lps_parts--slide .slick-arrow::after {
    content: '';
    display: block;
    width: 30px;
    height: 30px;
    transform: rotate(45deg);
    @include mq(med) {
      width: 20px;
      height: 20px;
    }
    @include mq(sp) {
      width: 16px;
      height: 16px;
    }
  }
  .lps_parts--slide .slick-prev::after {
    border-bottom: 1px solid;
    border-left: 1px solid;
  }
  .lps_parts--slide .slick-next::after {
    border-top: 1px solid;
    border-right: 1px solid;
  }
  .lps_parts--slide .slick-dots li {
    display: block;
    width: 12px;
    height: 12px;
    background-color: var(--clr-main);
    border-radius: 50%;
    cursor: pointer;
    transition: var(--transit-default, all .4s ease-out);
    margin: 6px;
    opacity: .5;
  }
  .lps_parts--slide .slick-dots li.slick-active {
    opacity: 1;
  }
  .lps_parts--slide .slick-dots li:hover {
    opacity: 1;
  }