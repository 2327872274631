@use '../abstracts' as *;

/* home common
********************************************** */
.home_ttl-en {
  font-family: var(--font-en);
  font-size: rem(80);
  letter-spacing: .05em;
  line-height: 1;
  margin-bottom: 32px;
  @include mq(sp) {
    font-size: sprem(46);
    letter-spacing: .05em;
    margin-bottom: 16px;
  }
}
.home_ttl {
  font-size: rem(40);
  letter-spacing: .05em;
  @include lh(40,67);
  margin-bottom: 64px;
  @include mq(sp) {
    font-size: sprem(24);
    letter-spacing: .05em;
    margin-bottom: 32px;
  }
}
.home_ttl.ttl-wh,
.home_ttl-en.ttl-wh {
  color: var(--clr-wht);
}
.home_ttl.ttl-br,
.home_ttl-en.ttl-br {
  color: var(--clr-main);
}
.scroll-content--wrap {
  white-space: nowrap;
}


/* home_top
********************************************** */
.home_top {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.hero--decobox {
  position: absolute;
  top: calc(500px + 13.8vw);
  z-index: 10;
  mix-blend-mode: multiply;
  @include mq(sp) {
    top: 368px;
  }
}
.hero--imagebox {
  position: absolute;
  top: calc(500px + 24vw);
  z-index: 11;
  @include mq(sp) {
    top: 400px;
  }
}
.hero--image {
  display: flex;
  width: fit-content;
  animation: scroll-right 50s linear infinite;
  gap: vw(56);
}
.hero--image > div {
  gap: vw(56);
  padding-top: 50px;
  padding-bottom: 20px;
  @include mq(sp) {
    padding-top: 25px;
    padding-bottom: 10px;
    gap: spvw(24);
  }
}
.hero--image img {
  width: vw(640);
  max-width: inherit;
  box-shadow: 50px 50px 50px rgba(0,0,0,0.2);
  @include mq(sp) {
    width: 180px;
  }
}
.hero--image img:nth-child(1) { translate: 0 20px; }
.hero--image img:nth-child(2) { translate: 0 -40px; }
.hero--image img:nth-child(4) { translate: 0 -50px; }
@include mq(sp) {
  .hero--image img:nth-child(1) { translate: 0 10px; }
  .hero--image img:nth-child(2) { translate: 0 -20px; }
  .hero--image img:nth-child(4) { translate: 0 -25px; }
}

.hero_content {
  position: relative;
  margin-top: 500px;
  color: var(--clr-blk);
  @include mq(sp) {
    margin-top: 240px;
  }
  &::before {
    content: '';
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: url(/wp/wp-content/themes/standard_sass/images/top/top_bg.png);
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: top 5vw center;
    z-index: 8;
    @include mq(sp) {
      background-image: url(/wp/wp-content/themes/standard_sass/images/top/top_bg_sp.png);
      background-position: top center;
    }
  }
  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 70%;
    bottom: 0;
    background-image: url(/wp/wp-content/themes/standard_sass/images/top/bg_pattern-ye.png);
    background-repeat: repeat;
    z-index: 7;
  }
}

.hero--scroll {
  position: absolute;
  top: 580px;
  right: vw(40);
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 10;
  @include mq(sp) {
    top: 240px;
  }
}
.scroll--txt {
  letter-spacing: .05em;
  margin-bottom: 24px;
  @include write-v;
  @include mq(sp) {
    font-size: sprem(12);
  }
}
.hero--scroll .border {
  position: relative;
  display: block;
  height: 440px;
  width: 1px;
  background-color: var(--clr-blk);
  @include mq(sp) {
    height: 180px;
  }
  &::before {
    content: '';
    position: absolute;
    top: 0;
    translate: -50% 0;
    width: 6px;
    height: 25px;
    background-color: var(--clr-blk);
    animation: scroll 2.5s cubic-bezier(0.38, 0, 0.12, 1) infinite;
    @include mq(sp) {
      width: 3px;
      height: 12px;
    }
  }
}


/* hero_movie
********************************************** */
.hero_movie {
  position: absolute;
  width: 100%;
  min-height: 1080px;
  aspect-ratio: 1920/1080;
  z-index: 1;
}
.video_box {
  position: absolute;
  top: 50%;
  left: 50%;
  translate: -50% -50%;
  overflow: hidden;
  width: 100%;
  height: 100%;
  @include mq(sp, min, ps) {
    min-width: 1920px;
  }
}
.video_box video {
  width: 100%;
  @include mq(sp, min, ps) {
    height: 100%;
    object-fit: cover;
  }
}
@include mq(sp) {
  .hero_movie {
    height: 540px;
  }
}

/* hero_content
**************************************** */
.hero--ttlbox {
  position: relative;
  margin-bottom: vw(800);
  z-index: 9;
  @include mq(sp) {
    margin-bottom: 260px;
  }
}
.hero--copy {
  position: relative;
  margin-left: vw(96);
  font-size: vw(150);
  letter-spacing: .12em;
  @include lh(210,240);
  white-space: nowrap;
  z-index: 4;
  @include mq(sp) {
    font-size: sprem(36);
    line-height: 1.3;
  }
}
.hero--deco {
  display: flex;
  width: fit-content;
  animation: scroll-left 80s linear infinite;
  gap: vw(100);
}
.hero--deco p {
  color: var(--clr-yel);
  font-size: vw(300);
  letter-spacing: .075em;
  line-height: .75;
  opacity: .8;
}


/* home_message
**************************************** */
.home_message {
  position: relative;
  padding-bottom: 300px;
  z-index: 9;
  @include mq(sp) {
    padding-bottom: 80px;
  }
}
.home_message .home_ttl-en {
  translate: vw(-56) 0;
  margin-bottom: 64px;
  @include mq(sp) {
    margin-bottom: 32px;
  }
}
.home_message .home_ttl {
  @include mq(sp) {
    font-size: sprem(22);
  }
}
.message_txt p {
  margin-bottom: 48px;
  font-size: rem(20);
  line-height: 2;
  @include mq(sp) {
    font-size: sprem(16);
    margin-bottom: 18px;
  }
}

/* home_feature
**************************************** */
.home_feature {
  position: relative;
  margin-top: -132px;
  padding-top: 280px;
  padding-bottom: 144px;
  background-color: var(--clr-br);
  background-image: url(/wp/wp-content/themes/standard_sass/images/top/feature_bg_img.png);
  background-repeat: no-repeat;
  background-position: left top 64px;
  background-size: vw(1174) auto;
  clip-path: polygon(0 132px, 100% 0, 100% 100%, 0 calc(100% - 132px));
  z-index: 10;
  @include mq(sp) {
    margin-top: spvw(-64);
    padding-top: 160px;
    padding-bottom: spvw(120);
    clip-path: polygon(0 spvw(64), 100% 0, 100% 100%, 0 calc(100% - spvw(64)));
  }
}
.home_feature .home_ttl {
  margin-bottom: 112px;
  @include mq(sp) {
    margin-bottom: 48px;
  }
}
.feature_content > div {
  @include mq(sp, min, ps) {
    width: 50%;
  }
}
.feature_info {
  padding: 20px vw(155) 20px vw(135);
  @include mq(1600) {
    @include p-lr(vw(50), vw(50));
  }
  @include mq(sp) {
    padding: 32px 16px;
  }
}
.feature_content .ttlarea {
  position: relative;
}
.feature--deco {
  position: absolute;
  top: 0;
  right: -110px;
  color: var(--clr-br);
  font-size: vw(166);
  line-height: .75;
  letter-spacing: .05em;
  opacity: .25;
  @include mq(1600) {
    right: 0;
  }
  @include mq(sp) {
    font-size: sprem(44)
  }
}
.feature--num {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  color: var(--clr-main);
  line-height: 1;
  letter-spacing: normal;
  @include mq(sp) {
    margin-bottom: 8px;
  }
  &::before {
    content: '';
    display: block;
    width: 14px;
    height: 19px;
    margin-right: 8px;
    background-image: url(/wp/wp-content/themes/standard_sass/images/top/drop_deco.svg);
    background-repeat: no-repeat;
  }
}
.feature--ttl {
  font-size: rem(28);
  @include lh(28, 44);
  margin-bottom: 48px;
  @include mq(1600) {
    margin-bottom: 24px;
  }
  @include mq(sp) {
    margin-bottom: 16px;
  }
}
.feature--ttl br {
  @include mq(sp) {
    display: none;
  }
}
.feature_content .txtarea p {
  @include lh(16, 30);
  letter-spacing: .05em;
}

/* home_products
********************************************** */
.home_products {
  padding-top: 140px;
  padding-bottom: 60px;
  @include mq(sp) {
    padding-top: 64px;
    padding-bottom: 32px;
  }
}
.products_ttl {
  margin-bottom: 180px;
  gap: vw(200);
  @include mq(sp) {
    align-items: flex-start;
    gap: 32px;
    margin-bottom: 64px;
  }
}
.products_ttl .ttlarea {
  white-space: nowrap;
}
.home_products .home_ttl {
  margin-bottom: 0;
}
.home_products .ttlsub p {
  font-size: rem(20);
  line-height: 2;
  letter-spacing: .05em;
}
.series--ttl {
  margin-bottom: 48px;
  color: var(--clr-main);
  font-size: rem(32);
  letter-spacing: normal;
  @include mq(sp) {
    margin-bottom: 24px;
    font-size: sprem(24);
  }
}
.series--txt p {
  @include lh(16, 30);
  letter-spacing: .05em;
  &:not(:last-child) {
    margin-bottom: 2em;
    @include mq(sp) {
      margin-bottom: 1em;
    }
  }
}
.searchingcut_box {
  background-color: var(--clr-wht);
  margin-right: vw(100);
  margin-bottom: 110px;
  padding-right: vw(140);
  @include mq(sp) {
    margin-right: 24px;
    @include p-lr(24px);
    margin-bottom: 56px;
  }
}
.searchingcut_box .txtarea {
  @include p-tb(100px, 80px);
  @include mq(sp) {
    @include p-tb(48px, 40px);
  }
}
.searchingcut_box .series--ttl {
  margin-bottom: 64px;
  @include mq(sp) {
    margin-bottom: 24px;
  }
}
.searchingcut_box .series--txt {
  margin-left: 48px;
  margin-bottom: 40px;
  @include mq(sp) {
    margin-left: 0;
  }
}
.searchingcut_box .series--btn {
  @include mq(sp) {
    justify-content: center;
  }
}
.searchingcut_box .imgarea {
  width: vw(904);
  aspect-ratio: 904 / 680;
  @include mq(sp, min, ps) {
    min-width: vw(904);
    margin-right: vw(56);
    padding-top: vw(680);
  }
  @include mq(sp) {
    width: 100%;
    margin-bottom: 24px;
    aspect-ratio: 900 / 820;
  }
}
.searchingcut_img01 {
  top: vw(-88);
  left: vw(100);
  width: vw(600);
  @include mq(sp) {
    top: 0;
    left: 0;
    width: 70%;
  }
}
.searchingcut_img02 {
  top: vw(154);
  right: 0;
  width: vw(329);
  @include mq(sp) {
    top: 20%;
    width: 40%;
  }
}
.searchingcut_img03 {
  left: vw(296);
  bottom: vw(16);
  width: vw(248);
  @include mq(sp) {
    left: 20%;
    bottom: 3%;
    width: 30%;
  }
}
.searchingcut--deco {
  top: vw(-120);
  right: vw(8);
  width: vw(220);
  height: vw(215);
  aspect-ratio: 220/215;
  @include mq(sp) {
    width: 30%;
    height: auto;
    aspect-ratio: 220/160;
    top: -5%;
    right: -1%;
  }
}
.searchingcut--deco01 {
  width: vw(113);
  @include mq(sp) {
    width: 40%;
  }
}
.searchingcut--deco02 {
  width: vw(153);
  right: 0;
  bottom: 0;
  @include mq(sp) {
    width: 60%;
  }
}

/* --- series_box --- */
.series_box {
  margin-bottom: 110px;
  @include mq(sp) {
    margin-bottom: 32px;
  }
}
.series_item {
  width: calc((100% - vw(64)) / 2);
  gap: 40px;
  padding: 80px 60px 50px;
  background-image: url(/wp/wp-content/themes/standard_sass/images/top/products_bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left center;
  box-shadow: -11px 6px 59px rgba(4,4,4,0.19);
  @include mq(sp) {
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-bottom: 32px;
    gap: 20px;
    padding: 40px 16px 24px;
    background-image: url(/wp/wp-content/themes/standard_sass/images/top/products_bg_sp.jpg);
    background-size: 100% auto;
    background-position: center bottom;
    background-color: var(--clr-wht);
  }
}
.series_item .imgarea {
  @include mq(sp, min, ps) {
    width: vw(249);
    min-width: vw(249);
  }
  @include mq(sp) {
    width: 40%;
  }
}

/* --- products_list --- */
.products_list {
  padding: 72px vw(120) 100px;
  margin-bottom: 156px;
  border: 1px solid var(--clr-main);
  @include mq(sp) {
    padding: 32px 16px 32px;
    margin-bottom: 64px;
  }
  &::before {
    content: '';
    position: absolute;
    width: vw(119);
    aspect-ratio: 119/92;
    top: vw(-20);
    left: vw(-15);
    background-image: url(/wp/wp-content/themes/standard_sass/images/top/products_drops01.png);
    background-repeat: no-repeat;
    background-size: 100%;
    @include mq(sp) {
      width: 20%;
      top: -1%;
      left: -5%;
    }
  }
  &::after {
    content: '';
    position: absolute;
    width: vw(104);
    aspect-ratio: 104/113;
    bottom: vw(4);
    right: vw(-70);
    background-image: url(/wp/wp-content/themes/standard_sass/images/top/products_drops02.png);
    background-repeat: no-repeat;
    background-size: 100%;
    @include mq(sp) {
      width: 18%;
      bottom: -6%;
      right: -5%;
    }
  }
}
.products_list--ttl {
  margin-bottom: 64px;
  color: var(--clr-main);
  font-size: rem(40);
  letter-spacing: .05em;
  text-align: center;
  @include mq(sp) {
    margin-bottom: 24px;
    font-size: sprem(24);
    letter-spacing: .05em;
  }
}
.products_list ul {
  display: grid; 
  grid-template-columns: 1fr 1fr; 
  gap: 16px;
  @include mq(sp) {
    grid-template-columns: 1fr; 
    gap: 8px;
  }
}
.products_list li {
  display: flex;
  align-items: center;
  font-size: rem(20);
  @include mq(sp) {
    align-items: flex-start;
    font-size: rem(16);
  }
  &::before {
    content: '';
    display: block;
    width: 14px;
    height: 19px;
    margin-right: 16px;
    background-image: url(/wp/wp-content/themes/standard_sass/images/top/drop_deco.svg);
    background-repeat: no-repeat;
    @include mq(sp) {
      min-width: 14px;
      margin-top: 2px;
      margin-right: 8px;
    }
  }
}

/* --- products_img --- */

.products_img {
  @include mq(sp, min, ps) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin-bottom: 148px;
  }
  @include mq(sp) {
    margin-bottom: 64px;
  }
  &.pc-none {
    display: none;
  }
}
.products_img img {
  width: 100%;
}

/* --- products_case --- */
.products_case {
  overflow: hidden;
}
.products_case--ttl {
  margin-bottom: 56px;
  color: var(--clr-main);
  font-size: rem(40);
  letter-spacing: .05em;
  text-align: center;
  @include mq(sp) {
    margin-bottom: 24px;
    font-size: sprem(28);
  }
}
.products_case--lead p {
  letter-spacing: .05em;
  &:not(:last-of-type) {
    margin-bottom: 2em;
    @include mq(sp) {
      margin-bottom: 1em;
    }
  }
}

/* --- case_slide --- */
.case_slide .slick-list {
  overflow: inherit;
}
.case_slide .slick-track {
  display:flex !important;
}
.case_slide .slick-slide {
  height: auto !important;
}
.case_slide .slick-arrow {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  width: vw(80);
  height: vw(80);
  border: 2px solid var(--clr-main);
  border-radius: 50vw;
  background-color: var(--clr-wht);
  box-shadow: -5px 3px 10px rgba(0,12,82,0.15);
  z-index: 10;
  transition: var(--transit-default);
  cursor: pointer;
  @include mq(sp, min, ps) {
    min-width: 64px;
    min-height: 64px;
  }
  @include mq(sp) {
    width: 32px;
    height: 32px;
  }
  &.prev {
    left: 15.2%;
    rotate: 180deg;
    @include mq(1700) {
      left: 10.2%;
    }
    @include mq(sp) {
      left: 1%;
    }
  }
  &.next {
    right: 15.2%;
    @include mq(1700) {
      right: 10.2%;
    }
    @include mq(sp) {
      right: 1%;
    }
  }
  .arrow {
    position: absolute;
    inset: 0;
    margin: auto;
    width: 10px;
    height: 10px;
    border-top: 2px solid var(--clr-main);
    border-right: 2px solid var(--clr-main);
    rotate: 45deg;
    @include mq(sp) {
      right: 4px;
      width: 8px;
      height: 8px;  
    }
  }
  &:hover {
    background-color: var(--clr-main);
    .arrow {
      border-color: var(--clr-wht);
    }
  }
}
.case_slide--wrap {
  &::before {
    content: '';
    position: absolute;
    width: vw(280);
    top: vw(-82);
    left: vw(144);
    aspect-ratio: 280/152;
    background-image: url(/wp/wp-content/themes/standard_sass/images/top/products_parts03.png);
    background-repeat: no-repeat;
    background-size: 100%;
    z-index: 10;
    @include mq(sp) {
      width: 25vw;
      top: -5vw;
      left: 5vw;
    }
  }
  &::after {
    content: '';
    position: absolute;
    right: vw(65);
    bottom: vw(-100);
    width: vw(365);
    aspect-ratio: 365/142;
    background-image: url(/wp/wp-content/themes/standard_sass/images/top/products_parts04.png);
    background-repeat: no-repeat;
    background-size: 100%;
    z-index: 10;
    @include mq(sp) {
      width: 35vw;
      bottom: -8vw;
      right: 2vw;
    }
  }
}
.case_item {
  margin-left: vw(60);
  margin-right: vw(60);
  padding: 60px vw(60);
  border:1px solid var(--clr-main);
  background-image: url(/wp/wp-content/themes/standard_sass/images/top/case_bg.jpg);
  background-size: cover;
  background-position: right  bottom;
  transition: var(--transit-default);
  &:hover {
    box-shadow: 5px 5px 15px rgb(0 12 82 / 15%);
  }
  @include mq(sp) {
    margin-left: spvw(8);
    margin-right: spvw(8);
    padding: 32px spvw(16) 24px;
    background-size: 200% auto;
    background-repeat: no-repeat;
    background-position: right  bottom;
    background-color: var(--clr-wht);
  }
}
.case_item .ttlarea {
  @include p-lr(vw(60),vw(20));
  @include mq(sp) {
    @include p-lr(0);
    margin-bottom: 16px;
  }
}
.case_item .ttltxt {
  width: calc(100% - vw(350) - 24px);
  @include mq(sp) {
    width: 100%;
  }
}
.case_slide--deco {
  margin-bottom: 32px;
  color: var(--clr-br);
  font-size: rem(32);
  letter-spacing: .05em;
  line-height: .75;
  opacity: .25;
  @include mq(sp) {
    font-size: sprem(26);
    margin-bottom: 16px;
  }
  .num {
    font-size: rem(48);
    @include mq(sp) {
      font-size: sprem(36);
    }
  }
}
.case_slide--ttl {
  margin-bottom: 32px;
  color: var(--clr-main);
  font-size: rem(32);
  line-height: 1;
  letter-spacing: normal;
  @include clip-txt;
  @include mq(sp) {
    margin-bottom: 24px;
    font-size: sprem(20);
  }
}
.case_slide--data {
  margin-bottom: 10px;
}
.case_slide--data dt {
  padding: 6px 12px;
  margin-right: 24px;
  color: var(--clr-wht);
  font-size: rem(15);
  letter-spacing: normal;
  line-height: 1;
  white-space: nowrap;
  background-color: var(--clr-main);
  border-radius: 50vw;
  @include mq(sp) {
    margin-right: 8px;
    font-size: sprem(12);
  }
}
.case_slide--data dd {
  color: var(--clr-main);
  font-size: rem(15);
  letter-spacing: normal;
  line-height: 1;
  @include clip-txt;
  @include mq(sp) {
    font-size: sprem(12);
  }
}
.case_item .imgarea {
  width: vw(350);
  aspect-ratio: 350/240;
  @include mq(sp) {
    width: 50%;
    @include auto-margin;
  }
}
.case_item .imgarea img {
  object-fit: contain;
  @include auto-margin;
}
.case_result {
  padding: 40px 60px 56px;
  border: 1px solid rgba($clr-main,.6);
  background-color: var(--clr-lbr3);
  @include mq(sp) {
    padding: 24px 16px;
    margin-bottom: 16px;
  }
}
.case_result dt {
  margin-bottom: 24px;
  color: var(--clr-main);
  font-size: rem(20);
  letter-spacing: .05em;
  @include mq(sp) {
    margin-bottom: 16px;
    font-size: sprem(16);
    letter-spacing: .05em;
  }
}
.case_result dd {
  letter-spacing: .05em;
  @include mq(sp) {
    font-size: sprem(14);
    letter-spacing: .05em;
  }
}
.case_price {
  padding: 32px 20px 24px;
  border: 1px solid rgba($clr-main,.6);
  background-color: rgba($clr-wht, .6);
  @include mq(1400) {
    gap: 8px;
    flex-direction: column;
    align-items: center;
  }
  @include mq(sp) {
    align-items: flex-start;
    padding: 24px 16px;
    text-align: center;
  }
}
.case_price > * {
  @include mq(sp) {
    width: 100%;
  }
} 
.case_price .editor-ttl {
  font-size: sprem(18);
}
.case_price table th,
.case_price table td {
  @include p-tb(10px);
  font-size: rem(20);
  letter-spacing: .05em;
  line-height: 1;
  border: 1px solid var(--clr-gry3);
  text-align: center;
  @include mq(1700) {
    font-size: rem(18);
  }
  @include mq(sp) {
    font-size: sprem(14);
  }
}
.case_price table th {
  background-color: var(--clr-gry4)
}
.case_price table td {
  @include p-lr(24px);
  width: vw(220);
  text-align: right;
  @include mq(1700) {
    width: auto;
    min-width: 140px;
  }
  @include mq(sp) {
    min-width: inherit;
    @include p-lr(8px);
  }
}
.case_price table .price-th {
  @include mq(sp, min, ps) {
    width: 250px;
  }
}
.case_price table th.price-after {
  color: var(--clr-wht);
  background-color: var(--clr-main);
}
.case_price table td.price-after {
  background-color: var(--clr-lbr3);
  white-space: nowrap;
}
.case_price table .blank {
  background-color: transparent;
  border: none;
}
.price-margin {
  font-size: rem(20);
  letter-spacing: .05em;
  @include mq(sp) {
    font-size: sprem(16);
  }
}
.price-margin .price {
  color: var(--clr-main);
}
.price-margin .price .num {
  font-size: rem(30);
  letter-spacing: .05em;
  @include mq(sp) {
    font-size: sprem(20);
  }
}


/* home_about
********************************************** */
.home_about {
  padding-top: 280px;
  background-color: var(--clr-br);
  background-image: url(/wp/wp-content/themes/standard_sass/images/top/about_bg_img.png);
  background-repeat: no-repeat;
  background-position: left top 64px;
  background-size: vw(1214) auto;
  clip-path: polygon(0 132px, 100% 0, 100% 100%, 0 100%);
  @include mq(sp) {
    padding-top: spvw(120);
    background-position: left top 8vw;
    background-size: 90% auto;
    clip-path: polygon(0 spvw(64), 100% 0, 100% 100%, 0 100%);
  }
}
.about_box {
  margin-bottom: 190px;
  @include mq(sp) {
    margin-bottom: 24px;
  }
}
.about_item {
  width: calc((100% - vw(64)) / 2);
  background-color: var(--clr-wht);
  @include mq(sp) {
    width: 100%;
    margin-bottom: 32px;
  }
}
.about_item .ttlarea {
  height: 200px;
  padding-left: vw(80);
  padding-right: vw(80);
  @include mq(sp) {
    height: 100px;
    @include p-lr(24px);
  }
}
.about_item .txtarea {
  padding-left: vw(80);
  padding-right: vw(80);
  @include mq(sp, min, ps) {
    height: 240px;
  }
  @include mq(sp) {
    padding: 24px;
  }
}
.about_item .txtarea p {
  @include mq(sp) {
    font-size: sprem(14);
    line-height: 1.6;
  }
}
.about_item .txtarea p br {
  @include mq(sp) {
    display: none;
  }
}
.about_item01 .ttlarea {
  background-image: url(/wp/wp-content/themes/standard_sass/images/top/about_img01.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}
.about_item02 .ttlarea {
  background-image: url(/wp/wp-content/themes/standard_sass/images/top/about_img02.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}

.about--deco {
  position: absolute;
  width: vw(278);
  right: vw(28);
  top: vw(30);
  pointer-events: none;
  @include mq(sp) {
    width: 23%;
    right: 5%;
    top: 0;
  
  }
}
.about_item02::after {
  content: "";
  position: absolute;
  top: vw(-80);
  right: vw(-64);
  width: vw(144);
  aspect-ratio: 144/134;
  background-image: url(/wp/wp-content/themes/standard_sass/images/top/about_parts01.png);
  background-repeat: no-repeat;
  background-size: 100%;
  pointer-events: none;
  @include mq(sp) {
    width: 17%;
    top: -10%;
    right: -7%;
  }
}
.about_ttl a {
  display: flex;
  align-items: center;
  color: var(--clr-wht);
  font-size: rem(32);
  letter-spacing: .05em;
  @include mq(sp) {
    font-size: sprem(22);
  }
  .cercle {
    position: relative;
    @include rect(32);
    min-width: 32px;
    margin-left: 40px;
    border: 1px solid var(--clr-wht);
    border-radius: 50vw;
    transition: var(--transit-default);
    @include mq(sp) {
      @include rect(24);
      min-width: 24px;
      margin-left: 24px;
    }
  }
  .arrow {
    position: absolute;
    inset: 0;
    margin: auto;
    width: 12px;
    height: 1px;
    border-top: 1px solid var(--clr-wht);
    transition: var(--transit-default);
    @include mq(sp) {
      width: 10px;
    }
    &::after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      margin: auto;
      width: 8px;
      height: 8px;
      border-top: 1px solid var(--clr-wht);
      border-right: 1px solid var(--clr-wht);
      transition: var(--transit-default);
      transform: rotate(45deg);
      @include mq(sp) {
        width: 6px;
        height: 6px;
      }
    }
  }
}
.about_item:hover {
  .about_ttl a {
    color: var(--clr-main);
  }
  .about_ttl a .cercle,
  .about_ttl a .arrow,
  .about_ttl a .arrow::after {
    border-color: var(--clr-main);
  }
}
.about_img img {
  width: 100%;
}

/* home_post
********************************************** */
.home_post {
  @include p-tb(140px);
  @include mq(sp) {
    @include p-tb(64px, 24px);
  }
}
.home_post--ttl {
  color: var(--clr-br);
  font-size: rem(40);
  letter-spacing: .05em;
  text-align: center;
  margin-bottom: 32px;
  @include mq(sp) {
    font-size: sprem(28);
    margin-bottom: 24px;
  }
}
.home_post .post_box {
  width: calc((100% - vw(64)) / 2);
  @include mq(sp) {
    width: 100%;
    margin-bottom: 40px;
  }
}
.post_list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  @include p-lr(vw(54));
  margin-bottom: 40px;
  border: 1px solid var(--clr-gry);
  @include mq(sp) {
    min-height: 100px;
    padding: 8px 16px;
    margin-bottom: 24px;
  }
}
.home_post .post_list .post {
  padding: 32px 16px;
  border-bottom: 1px solid var(--clr-gry);
  overflow: hidden;
  @include mq(sp) {
    padding: 16px 8px;
  }
  &:last-of-type {
    border-bottom: none;
  }
}
.home_post .post_list .post--date {
  color: var(--clr-gry);
  font-size: rem(13);
  line-height: 1;
  white-space: nowrap;
}
.home_post .post_list .post--ttl {
  margin-bottom: 8px;
  @include mq(sp, min, ps) {
    line-height: 1;
    @include clip-txt;
  }
  @include mq(sp) {
    @include clip-txt(2);
  }
}
.home_post .post_list .post--txt {
  font-size: rem(14);
  color: var(--clr-gry2);
  @include clip-txt(2);
}
.post_box .btn a {
  font-size: rem(16);
  @include p-lr(80px);
}
.home_post .post:hover .post--ttl a {
  color: var(--clr-main);
}

/* --- news_list --- */

.home_post .news_list .post--info {
  margin-right: vw(56);
}
.home_post .post--ttl {
  font-size: rem(20);
}
.home_post .post--ttl a {
  display: block;
}

/* --- blog_list --- */
.home_post .post_list.blog_list .post {
  @include p-tb(24px);
  @include mq(sp) {
    @include p-tb(16px);
  }
}
.home_post .blog_list .post--img {
  width: 136px;
  min-width: 136px;
  aspect-ratio: 136/104;
  margin-right: 24px;
  @include mq(sp) {
    width: 80px;
    min-width: 80px;
    margin-right: 16px;
  }
}
.home_post .blog_list .post--info {
  margin-bottom: 16px;
  @include mq(sp) {
    margin-bottom: 8px;
  }
}
.home_post .blog_list .post--ttl {
  font-size: rem(18);
} 
.home_post .blog_list .cat_list {
  display: none;
}
