@use "../abstracts" as *;

/* bg
********************************************** */
.lps_sec:nth-child(2n) {
  background-color: var(--clr-lbr);
}
.bg-main {
  background-color: var(--clr-main);
}
.bg-wh {
  background-color: var(--clr-wht);
}
.bg-ptn {
  background-image: url(/wp/wp-content/themes/standard_sass/images/common/bg_pattern.png);
  background-repeat: repeat;
}