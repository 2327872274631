@use '../abstracts' as *;

/* ##############################################################################

  FORMY

############################################################################## */
/*! purgecss start ignore */
#formy_form table { width: 100%; }
#formy_form th,
#formy_form td {
  padding: 16px;
  vertical-align: middle;
  border-bottom: solid 1px rgba($clr-main, .3);
  @include mq(sp) {
    display: block;
    width: auto;
    @include p-lr(0);
  }
}
#formy_form th {
  font-weight: normal;
  white-space: nowrap;
  text-align: left;
  width: 34%;
  @include mq(sp) {
    width: 100%;
    border-bottom:none;
    padding-bottom: 0;
    margin-bottom: 8px;
    white-space: normal;
    font-weight: bold;
  }
}
#formy_form table input[type="text"],
#formy_form table input[type="email"],
#formy_form table input[type="tel"],
#formy_form table input[type="date"],
#formy_form table input[type="password"],
#formy_form table textarea {
  width: 100%;
  border-radius: 3px;
  vertical-align: bottom;
}
#formy_form table input[type="text"],
#formy_form table input[type="email"],
#formy_form table input[type="tel"],
#formy_form table input[type="date"],
#formy_form table input[type="password"],
#formy_form select,
#formy_form textarea {
  margin: 0;
  padding: 5px 15px;
  border: 1px solid #ccc;
  font: inherit;
  font-size: 1rem;
  font-family: YuGothic, "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", sans-serif;
  font-weight: 700;
}
#formy_form textarea { height: 100px; }
#formy_form select { 
  height: 40px;
  color: var(--clr-blk);
  background-color: var(--clr-wht);
  border: 1px solid #ccc;
  border-radius: 3px;
}
#formy_form ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
#formy_form input:hover { opacity:.7; }
#formy_form textarea:hover { opacity:.7; }
#formy_form input:focus { outline: none; }
#formy_form .parsley-validated {  background-color: #eee; }
#formy_form .parsley-error {  background-color: #fee; }
#formy_form .parsley-success {  background-color: #fff; }
.help_text {
  font-size: .875rem; /* 14px */
  color: #999;
}
.hidden_help { display: none; }
.formy_privacy div {
  overflow-y: scroll;
  height: 140px;
  border: solid 1px #ccc;
  font-size: .875rem; /* 14px */
  padding: 8px 16px;
}
.requiredIcon {
  background-color: var(--clr-red);
  color: #fff;
  margin: 0 0 0 1em;
  font-size: .75rem; /* 12px */
  padding: 2px 5px;
  border-radius: 3px;
  float: right;
}
#formy_btn {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  padding-top: 32px;
  text-align: center;
  @include mq(sp) {
    padding-top: 8px;
  }
}
#formy_btn input {
  font-size: inherit;
  border: none;
  cursor: pointer;
  color: #fff;
  border-radius: 3px;
  padding: 16px 32px;
  margin-bottom: 24px;
  -webkit-appearance: none;
  appearance: none;
  @include mq(sp) {
    width: 100%;
    background-size: contain;
    background-position: center;
  }
}
#formy_form ul li input[type="radio"],
#formy_form ul li input[type="checkbox"] { display: none !important; }
#formy_form ul li label {
  position: relative;
  display: inline-block;
  padding: .5em 8px .5em 40px;
  line-height: 1.6;
  vertical-align: top;
  cursor: pointer;
}
#formy_form ul li label:hover { opacity: .7; }
#formy_form ul li label::before {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  border: 2px solid #788b93;
  left: 16px;
  top: 12px;
}
#formy_form ul li input[type="radio"] + label::before { border-radius: 10px; }
#formy_form ul li input[type="radio"]:checked + label,
#formy_form ul li input[type="checkbox"]:checked + label {
  color: var(--clr-main);
  font-weight: bold;
}
#formy_form ul li input[type="radio"]:checked + label::before,
#formy_form ul li input[type="checkbox"]:checked + label::before {
  border-color: var(--clr-main);
}
#formy_form ul li input[type="radio"]:checked + label::after,
#formy_form ul li input[type="checkbox"]:checked + label::after {
  content: "";
  width: 10px;
  height: 18px;
  top: 4px;
  left: 20px;
  border-right: 2px solid var(--clr-main);
  border-bottom: 2px solid var(--clr-main);
  display: block;
  position: absolute;
  z-index: 10;
  transform: rotate(45deg);
}
.formy_confirm { background-color: var(--clr-main); }
.formy_submit_disabled { background-color: #ccc; }
#formy_btn .formy_submit_disabled:hover {
  opacity: 1;
  cursor: default;
}
#formy_btn input.autoConfirmBack {
  position: relative;
  background-color: transparent;
  color: #555;
  padding: 8px 27px;
  min-width: auto;
  font-size: 92%;
  border: 1px solid #aaa;
  border-radius: 32px;
}
.formy_send { background-color: #ff6600; }
#total_required {
  padding: 16px;
  color: var(--clr-red);
  text-align: center;
}

@media print, screen and (min-width: 768px) {
  #formy_form .confirm-message {
    text-align: center;
  }
}
@include mq(sp) {
  #formy_form td { padding-top: 0; }
  .autoConfirmBack { margin-bottom: 8px; }
}
/*! purgecss end ignore */