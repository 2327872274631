/* ##############################################################################

    KEYFRAMES

############################################################################## */

@keyframes scroll-left {
  0% { transform: translate(-25%, 0%) }
  100% { transform: translate(-75%, 0%) }
}
@keyframes scroll-right {
  0% { transform: translate(-75%, 0%) }
  100% { transform: translate(-25%, 0%) }
}

@keyframes scroll {
  0% {
    top: 0;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    top: 100%;
  }
}