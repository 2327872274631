@use "../abstracts" as *;

/* ttl
********************************************** */
/* --- page_ttl --- */
.page_ttl {
  position: relative;
  color: var(--clr-wht);
  z-index: 10;
  overflow: hidden;
  background-color: #eee;
}
.page-404 .page_ttl {
  background-color: var(--clr-br);
}
.page_ttl--bg-wrap {
  height: 480px;
  @include mq(sp) {
    height: 120px;
  }
}
.page-contact .page_ttl--bg-wrap {
  height: 240px;
  @include mq(sp) {
    height: 100px;
  }
}
.page_ttl--bg {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  background-size: cover;
  background-position: center;
}
.page_ttl-jp {
  font-family: var(--font-jp);
  font-size: rem(40);
  letter-spacing: .1em;
  @include mq(sp) {
    font-size: 2rem; /* 28px */
  }
}
.page_ttl-en {
  display: block;
  text-transform: uppercase;
  font-size: rem(18);
  font-family: var(--font-en2);
  font-weight: 700;
  letter-spacing: .1em;
  @include mq(sp) {
    font-size: 1.143rem; /* 16px */
  }
}

/* --- ttl --- */
.ttl-01,
.ttl-02,
.ttl-03 {
  font-family: var(--font-jp);
  letter-spacing: 0.1em;
}
.ttl-01,
.ttl-02,
.ttl-03 {
  font-family: var(--font-en);
  font-weight: 700;
}
.ttl-01 {
  font-size: rem(48);
  color: var(--clr-main);
  @include mq(sp) {
    font-size: sprem(30);
  }
}
.ttl-02 {
  font-size: rem(40);
  @include mq(sp) {
    font-size: sprem(28);
  }
}
.lps_parts--img_text .inner-lg .ttl-03 {
  font-size: rem(40);
  @include mq(sp) {
    font-size: sprem(24);
  }
}
.ttl-03 {
  font-size: rem(28);
  @include mq(sp) {
    font-size: sprem(24);
  }
}
.lps_sec .ttl-01,
.lps_sec .ttl-02,
.lps_sec .ttl-03 {
  position: relative;
  z-index: 1;
}
.lps_sec .ttl-01,
.lps_sec .ttl-02,
.lps_sec .ttl-03:not(:last-child) {
  margin-bottom: 1em;
}
.ttl-04 {
  font-size: 1.5rem; /* 24px */
}
.lps_sec .column-3 .ttl-03,
.lps_sec .column-4 .ttl-03 {
  font-size: 1.5rem; /* 24px */
  @include mq(sp) {
    font-size: 1.714rem; /* 24px */
  }
}
