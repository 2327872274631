@use '../abstracts' as *;

/* ##############################################################################

    ARCHIVE

############################################################################## */

/* main_column
**************************************** */
.main_column {
  flex: 1;
  margin-right: vw(60);
  overflow: hidden;
  @include mq(1100) {
    width: 100%;
    margin-bottom: 40px;
    margin-right: 0;
  }
  @include mq(sp) {
    margin-bottom: 48px;
  }
}

/* --- post --- */
.post {
  position: relative;
}
.post--link::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.post--date {
  color: #aaa;
}
.post--info.flex .post--date + .cat_list {
  margin-left: auto;
}
.cat_list {
  position: relative;
  z-index: 1;
  overflow: hidden;
} 
.cat_list a {
  display: inline-block;
  vertical-align: middle;
  font-size: 0.813rem; /* 13px */
  background-color: var(--clr-main, #1d7fb7);
  color: #fff;
  padding: 0.25em 1em;
  float: left;
  white-space: nowrap;
  margin: 2px;
}
.cat_list a:hover {
  opacity: 0.7;
}

@include mq(1100) {
  .container > .inner.flex {
    display: block;
  }
}

/* side_column
**************************************** */
.side_column {
  width: 264px;
  @include mq(1100) {
    width: 100%;
  }
}
.side_section:not(:last-child) {
  margin-bottom: 64px;
}
.side--ttl {
  letter-spacing: 0.2em;
  line-height: 1;
  margin-bottom: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid rgba($clr-main , .6);
}
.side--ttl small {
  display: block;
  margin-top: 8px;
  font-family: var(--font-en);
  color: var(--clr-main);
  letter-spacing: 0.15em;
  line-height: 1;

}

/* --- list --- */
.side--list {
  margin-left: 2em;
  list-style-type: circle;
}
.side--list li::marker {
  color: var(--clr-red);
  font-size: rem(16);
}
.side--list a {
  display: block;
}

/* --- post --- */
.posts-side .post:not(:last-child) {
  margin-bottom: 16px;
}
.posts-side .post--img {
  margin-right: 16px;
  width: 64px;
  height: 64px;
}
.posts-side .txtarea {
  flex: 1;
}
.posts-side .post--date {
  margin-bottom: 0;
}
.posts-side .post--ttl {
  line-height: 1.4;
  @include clip-txt(2);
}

/* --- archive --- */
.side_column .archive_list--ttl {
  cursor: pointer;
  font-weight: 500;
  line-height: 1.8;
}
.side_column .archive_list--ttl::after {
  content: "";
  display: block;
  width: 8px;
  height: 8px;
  border-top: 1px solid;
  border-right: 1px solid;
  transform: rotate(135deg);
  transition: var(--trans-default, all 0.4s ease-out);
}
.side_column .archive_list--ttl.active::after {
  transform: rotate(315deg);
}
.side_column .archive_month {
  display: none;
}

/* --- archive-pulldown --- */
.archive-pulldown {
  position: relative;
  margin-left: auto;
  z-index: 1;
}
.archive-pulldown .archive_list {
  position: relative;
}
.archive-pulldown .archive_list:not(:last-child) {
  margin-right: 24px;
}
.archive-pulldown .archive_list a {
  display: block;
  padding: 0.25em 1em;
  text-align: left;
}
.archive-pulldown .archive_list a:not(:last-child) {
  border-bottom: 1px solid #eee;
}
.archive-pulldown .archive_list a:hover {
  background-color: #eee;
}
.archive-pulldown .archive_list--label {
  margin-right: 8px;
}
.archive-pulldown .archive_list--btn {
  cursor: pointer;
  border: none;
  outline: none;
  appearance: none;
  padding: 0.25em 4em 0.25em 2em;
  background-color: #fff;
  border: 1px solid #ccc;
  font-family: var(--font-primary, "YuGothic", "游ゴシック", "ヒラギノ角ゴ Pro W3",
    "Hiragino Kaku Gothic ProN", sans-serif);
  font-size: 1rem;
  line-height: 1.8;
  letter-spacing: 0.08em;
}
.archive-pulldown .archive_list--btn::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0.4em;
  right: 0.8em;
  margin: auto;
  width: 0.6em;
  height: 0.6em;
  border-bottom: 1px solid;
  border-right: 1px solid;
  transform: rotate(45deg);
  transition: all 0.2s ease-out;
}
.archive-pulldown .active .archive_list--btn::after {
  bottom: -0.2em;
  transform: rotate(225deg);
}
.archive-pulldown .archive_list--menu {
  position: absolute;
  z-index: 1;
  width: 100%;
  background-color: #fff;
  border: 1px solid #ccc;
  visibility: hidden;
  margin-top: -1px;
  opacity: 0;
  transition: all 0.2s ease-out;
}
.archive-pulldown .active .archive_list--menu {
  visibility: visible;
  opacity: 1;
}


/* blog
**************************************** */
.posts-blog .post {
  padding-bottom: 48px;
  padding-top: 48px;
  border-bottom: 1px solid rgba($clr-main , .6);
}
.posts-blog .post:first-child {
  border-top: 1px solid rgba($clr-main , .6);
}
.posts-blog .post--txtarea {
  flex: 1;
  word-break: break-word;
}
.posts-blog .post--img {
  width: 280px;
  margin-right: 40px;
}
.posts-blog .post--img::before {
  padding-top: 75%;
}

@include mq(sp) {  
  .posts-blog .post {
    display: block;
    padding-top: 24px;
    padding-bottom: 24px;
  }
  .posts-blog .post--img {
    padding-left: 0;
    @include auto-margin;
    margin-bottom: 16px;
    text-align: center;
  }
  .posts-blog .post--img img {
    width: auto;
    max-width: 100%;
  }
}

/* news
**************************************** */

/* --- news--archive --- */
.news--archive {
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: #111;
}
.news--archive li {
  @include auto-margin(8);
}
.news--archive a {
  color: #fff;
}

/* --- news_list --- */
.news_list .post {
  padding-bottom: 16px;
  padding-top: 16px;
  margin-bottom: 0;
  border-bottom: 1px solid #dedede;
  @include mq(sp) {
    display: block;
  }
}
.news_list .post--info {
  margin-right: 32px;
  @include mq(sp) {
    margin-bottom: 8px;
    margin-right: 0;
  }
}
.news_list .post--link:not([href="javascript:void(0);"]):hover {
  color: var(--clr-main, #1d7fb7);
}
.news_list .post--link:hover svg {
  fill: var(--clr-main, #1d7fb7);
}
.news_list .post--info.flex .post--date + .cat_list {
  margin-left: 16px;
}

/* case
**************************************** */
.posts-case {
  &::before {
    content: '';
    display: block;
    width: calc((100% - vw(60)) / 3);
    order:1;
  }
}
.posts-case .post {
  width: calc((100% - vw(60)) / 3);
  margin-bottom: vw(64);
  border: 1px solid var(--clr-main);
  &:hover,
  &:hover a {
    color: var(--clr-main);
  }
  @include mq(sp) {
    width: 100%;
    margin-bottom: 24px;
  }
}
.posts-case .post--img {
  aspect-ratio: 352/204;
}
.posts-case .post--img img {
  @include mq(sp) {
    width: 50%;
    @include auto-margin;
  }
}
.posts-case .case_box-img .post--img {
  background-color: var(--clr-wht);
  aspect-ratio: 352/264;
  overflow: hidden;
  @include mq(sp) {
    aspect-ratio: 16/9;
  }
}
.posts-case .post--txtarea {
  padding: 16px 20px 8px;
  @include mq(sp) {
    padding: 16px 16px 8px;
  }
}
.posts-case .case_box-img .post--txtarea {
  background-image: url(/wp/wp-content/themes/standard_sass/images/case/jirei_catch_logo.png);
  background-repeat: no-repeat;
  background-position: right -30px bottom -30px;
}
.posts-case .post--ttl {
  width: 100%;
  @include clip-txt(2);
  line-height: 1.4;
}
.posts-case .post--txt {
  @include clip-txt(4);
  font-family: var(--font-jp-m);
  letter-spacing: .05em;
  @include lh(14,25);
  transition: var(--transit-default);
}
.posts-case .case_box-img .post--txt {
  @include clip-txt(2);
}
.post--price {
  gap: 8px;
  color: var(--clr-main);
  line-height: 1;
}

/* product
**************************************** */
.posts-product {
  &::before {
    content: '';
    display: block;
    width: calc((100% - vw(60)) / 3);
    order:1;
  }
}
.posts-product .post {
  width: calc((100% - vw(60)) / 3);
  margin-bottom: vw(30);
  @include mq(sp) {
    width: 100%;
    margin-bottom: 24px;
  }
}
.posts-product .post--img {
  margin-bottom: 16px;
  background-color: #eee;
  aspect-ratio: 4/3;
  overflow: hidden;
}
.posts-product .editor-ttl.product_cat--ttl {
  padding-left: 0;
  font-size: rem(16);
  line-height: 1;
}
.product_cat--list {
  padding: 0 0;
}
.product_cat--list .cat_list a {
  font-size: rem(10);
  padding: 0.25em .5em;
  margin: 1px;
}
a.product_cate--link:hover {
  color: var(--clr-wht);
  opacity: .7;
}