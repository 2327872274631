@use '../abstracts' as *;

/* ##############################################################################

    HEADER

############################################################################## */

/* header
**************************************** */
.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 88px;
  z-index: z(header);
  background-color: var(--clr-wht);
  transition: var(--transit-default, all 0.4s ease-out);
  & > .inner {
    width: 100%;
    max-width: 100%;
    height: 100%;
    padding-left: vw(40);
  }
  @include mq(med) {
    height: 64px;
    & > .inner {
      align-items: center;
      padding-left: 30px;
    }
  }
}
.home .header {
  background-color: transparent;
  @include mq(med, min, ps) {
    height: 110px;
    padding-top: 24px;
  }
}
.home .header :has( .gnav.active ) {
  background-color: var(--clr-wht);
}
.page-contact .header {
  position: absolute;
}
.header--logo {
  width: vw(122);
  max-width: 122px;
  @include mq(med, min, ps) {
    position: absolute;
    top: 32px;
    min-width: 80px;
  }
  @include mq(med) {
    width: auto;
    height: 86%;
  }
}
.header--logo a,
.footer--logo a {
  display: block;
}
.header--logo a {
  @include mq(med) {
    height: 100%;
  }
}
.header--logo a:hover,
.footer--logo a:hover {
  opacity: 0.7;
}
.header--logo svg {
  width: 100%;
  @include mq(med) {
    width: auto;
    height: 100%;
  }
}

@include mq(med, min, ps) {
  .header-sm,
  .home .header-sm {
    height: 88px;
    padding-top: 0;
    background-color: var(--clr-wht2);
  }
  body:not(.home) .header--logo,
  .header-sm .header--logo {
    top: 8px;
  }
  body:not(.home) .header--logo a,
  .header-sm .header--logo a {
    height: 72px;
  }
  body:not(.home) .header--logo svg,
  .header-sm .header--logo svg {
    width: auto;
    height: 100%;
  }
}
@include mq(med) {
  .header--logo img {
    width: auto;
    max-height: 40px;
  }
}

/* gnav
********************************************** */
.gnav a {
  display: flex;
  @include center-flex;
}
.gnav--link {
  padding: 0 vw(24);
  @include mq(1500) {
    padding: 0 vw(16);
  }
}
html:not(.ipad) .gnav--menu > li > .gnav--link[href=""] {
  color: inherit;
  cursor: default;
  pointer-events: none;
}
.head_sub_nav--wrap {
  display: block;
  width: 140%;
  position: absolute;
  left: 50%;
  translate: -50% 0;
  z-index: 100;
  transition: var(--transit-default, all 0.4s ease-out);
  opacity: 0;
  pointer-events: none;
  @include mq(1400) {
    width: 160%;
  }
}
.head_sub_nav {
  margin-top: 8px;
}
.header-sm .head_sub_nav {
  margin-top: 16px;
}
.menu-item-has-children {
  position: relative;
}
.head_sub_nav li:not(:last-child) {
  border-bottom: 1px solid #eee;
}
.head_sub_nav a {
  display: block;
  padding: 16px 16px;
}

/* ---CTA --- */
.cta_tel a {
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: var(--font-en);
  font-weight: 700;
  line-height: 1;
}
.gnav_cta.cta_tel a {
  align-items: flex-end;
}
.cta_tel a > span {
  display: block;
}
.cta_tel .tel {
  font-size: rem(24);
  letter-spacing: normal;
  margin-bottom: 4px;
  @include mq(sp) {
    font-size: sprem(20);
  }
}
.cta_tel .num {
  font-size: rem(34);
  letter-spacing: normal;
  @include mq(sp) {
    font-size: sprem(28);
  }
}
.cta_tel .hours {
  font-family: var(--font-jp);
  font-size: rem(16);
  letter-spacing: .05em;
  @include mq(sp) {
    font-size: sprem(13);
  }
}
.gnav--tel {
  margin-left: vw(64);
  @include mq(1500) {
    margin-left: vw(40);
  }
}
.gnav--contact {
  margin-left: vw(32);
  @include mq(1500) {
    margin-left: vw(24);
  }
}
.gnav--tel a {
  display: flex !important;
  flex-direction: column;
}
.cta_contact {
  font-size: rem(20);
  letter-spacing: .075em;
  background-color: var(--clr-main);
  transition: var(--transit-default);
  &:hover {
    background-color: var(--clr-red);
  }
}
.cta_contact a {
  color: var(--clr-wht);
}
.gnav--contact {
  width: vw(260);
  height: 100%;
  @include p-lr(vw(20));
  @include mq(1500) {
    width: vw(240);
    min-width: 150px;
    font-size: rem(16);
  }
  @include mq(med) {
    width: 100%;
    height: 60px;
  }
}
.gnav--contact a {
  height: 100%;
  &:hover {
    color: var(--clr-wht);
  }
}
.gnav--contact svg {
  fill: #fff;
  margin-right: vw(28);
  @include mq(1500) {
    margin-right: vw(10);
  }
}
.gnav--tel .tel {
  font-size: rem(24);
  @include mq(1500) {
    font-size: rem(18);
  }
}
.gnav--tel .num {
  font-size: rem(34);
  @include mq(1500) {
    font-size: rem(24);
  }
}
.gnav--tel .hours {
  font-size: rem(16);
  @include mq(1500) {
    font-size: rem(13);
  }
}
.gnav--product {
  position: relative;
  width: auto;
  height: 100%;
  margin-left: vw(20);
  transition: var(--transit-default);
}
.gnav--product a {
  position: static;
  display: flex;
  @include center-flex;
  height: 100%;
  transition: var(--transit-default);
  scale: 1;
  &:hover {
    scale: 1.03;
  }
}
.gnav--product a img {
  width: auto;
  height: 90%;
}
@include mq(med, min, ps) {
  .home .header:not(.header-sm) .gnav--product {
    width: vw(116);
    height: 100%;
    margin-left: vw(7);
  }
  .home .header:not(.header-sm) .gnav--product a {
    position: absolute;
    top: -12px;
    height: auto;
  }
  .home .header:not(.header-sm) .gnav--product a img {
    width: 100%;
    height: auto;
  }
}

@include mq(med, min, ps) {
  .gnav {
    height: 100%;
  }
  .gnav {
    display: flex;
    align-items: flex-end;
  }
  .gnav_btn,
  .tel_btn {
    display: none;
  }
  .gnav--link {
    display: flex;
    flex-direction: column;
    @include center-flex;
    font-size: rem(16);
    letter-spacing: .05em;
    line-height: 1;
    text-align: center;
    @include mq(1500) {
      font-size: rem(15);
    }
  }
  .gnav--link .font-en2 {
    margin-top: 12px;
    margin-bottom: 2px;
    font-size: rem(12);
    font-weight: 800;
    letter-spacing: .025em;
  }
  .gnav--menu > li {
    border-left: 1px solid var(--clr-blk);
  }
  .gnav--menu > li:last-child {
    border-right: 1px solid var(--clr-blk);
  }
  .gnav--menu > li > .gnav--link:not([href="javascript:void(0);"]):hover{
    color: var(--clr-main);
  }
  .head_sub_nav a {
    background-color: var(--clr-wht2);
  }
  .gnav_sub_toggle {
    display: none;
  }
 .gnav--contact a {
    border-radius: 0;
  }

  /* hover */
  .gnav--menu .menu-item-has-children:hover .head_sub_nav--wrap {
    opacity: 1;
    pointer-events: auto;
  }
  .head_sub_nav a:hover {
    color: var(--clr-wht);
    background-color: var(--clr-main);
  }
}
@include mq(med) {
  .gnav,
  .gnav_overlay {
    position: fixed;
    top: 64px;
    right: 0;
    height: calc(100% - 64px);
    opacity: 0;
    pointer-events: none;
    transition: var(--transit-default, all 0.4s ease-out);
  }
  .gnav {
    width: 70%;
    margin: 0;
    padding: 24px 7%;
    overflow: scroll;
    background-color: #f7f7f7;
    z-index: 9999;
    transform: translateX(100%);
    -webkit-overflow-scrolling: touch;
  }
  .gnav_overlay {
    width: 100%;
    background-color: rgba(255, 255, 255, 0.8);
  }
  .gnav.active,
  .gnav_overlay.active {
    opacity: 1;
    pointer-events: auto;
  }
  .gnav.active {
    transform: translateX(0);
  }
  .gnav--menu,
  .gnav--cta {
    display: block;
  }
  .gnav--menu {
    margin-bottom: 24px;
  }
  .gnav--menu li {
    position: relative;
    display: block;
    width: 100%;
  }
  .gnav--menu li:not(:last-child) {
    border-bottom: 1px dotted var(--clr-main, #1d7fb7);
  }
  .gnav--menu a {
    display: block;
    padding: 18.5px 16px;
  }
  .gnav--menu a .font-en2 {
    display: none;
  }
  .gnav--menu li:not(.menu-item-has-children) a::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 10px;
    margin: auto;
    width: 7px;
    height: 7px;
    border-top: 1px solid var(--clr-main, #1d7fb7);
    border-right: 1px solid var(--clr-main, #1d7fb7);
    transform: rotate(45deg);
  }
  .head_sub_nav--wrap {
    position: relative;
    width: 100%;
    border-top: 1px dotted var(--clr-main, #1d7fb7);
    transition: none;
    opacity: 1;
    pointer-events: inherit;
    display: none;
  }
  .menu-item-has-children.active .head_sub_nav--wrap {
    opacity: 1;
    pointer-events: auto;
  }
  .head_sub_nav a {
    padding-left: 32px !important;
  }
  .gnav_sub_toggle {
    position: absolute;
    top: 0;
    right: -20px;
    width: 64px;
    height: 64px;
    transition: var(--transit-default, all 0.4s ease-out);
    color: var(--clr-main, #1d7fb7);
  }
  .gnav_sub_toggle::before,
  .gnav_sub_toggle::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 11px;
    height: 1px;
    border-bottom: 1px solid;
  }
  .gnav_sub_toggle::after {
    transform: rotate(90deg);
  }
  .gnav_sub_toggle.active {
    transform: rotate(180deg);
  }
  .head_sub_nav {
    position: relative;
  }
  .tel_btn,
  .gnav_btn {
    position: fixed;
    top: 0;
    width: 64px;
    height: 64px;
    z-index: 9999;
  }
  .tel_btn {
    right: 64px;
  }
  .tel_btn a {
    display: flex !important;
    width: 100%;
    height: 100%;
  }
  .tel_btn svg {
    fill: var(--clr-main, #1d7fb7);
  }
  .gnav_btn {
    right: 0;
    transition: var(--transit-default, all 0.4s ease-out);
  }
  .gnav_btn.active {
    background-color: #f7f7f7;
  }
  .gnav_btn--lines {
    position: relative;
    width: 24px;
    height: 16px;
  }
  .gnav_btn--lines span {
    transition: var(--transit-default, all 0.4s ease-out);
    box-sizing: border-box;
  }
  .gnav_btn--lines span {
    position: absolute;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: var(--clr-main, #1d7fb7);
  }
  .gnav_btn--lines span:nth-of-type(1) {
    top: 0;
  }
  .gnav_btn--lines span:nth-of-type(2) {
    top: 7px;
  }
  .gnav_btn--lines span:nth-of-type(3) {
    bottom: 0;
  }
  .active .gnav_btn--lines span:nth-of-type(1) {
    transform: translateY(7px) rotate(-45deg);
  }
  .active .gnav_btn--lines span:nth-of-type(2) {
    opacity: 0;
  }
  .active .gnav_btn--lines span:nth-of-type(3) {
    transform: translateY(-7px) rotate(45deg);
  }

  /* ---CTA --- */
  .gnav--cta li:not(:last-child) {
    margin-bottom: 16px;
  }
  .gnav--tel {
    margin-left: 0;
    text-align: center;
  }
  .gnav--contact {
    margin-left: 0;
  }
  .gnav--contact a {
    padding: 10px 16px;
    margin-left: auto;
    margin-right: auto;
  }
  .gnav--contact {
    margin-left: 0;
  }
  .gnav--tel {
    margin-bottom: 16px;
  }
  .gnav--contact {
    margin-bottom: 24px;
  }
  .gnav--product {
    width: 100%;
    height: auto;
    margin-left: 0;
  }
  .gnav--product a img {
    width: 50%;
    height: auto;
  }
}
@include mq(sp) {
  .gnav--menu a {
    padding: 19px 16px;
  }
  .gnav--contact svg {
    width: 16px;
    height: 11px;
  }
}