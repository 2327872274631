@use '../abstracts' as *;
/* fix_cta
**************************************** */
.fix_cta {
  position: fixed;
  right: vw(100);
  bottom: 20px;
  width: 356px;
  height: 145px;
  text-align: center;
  background-color: var(--clr-main);
  background-image: url(/wp/wp-content/themes/standard_sass/images/common/cta-bg.svg);
  background-size: auto;
  background-repeat: no-repeat;
  background-position: center center;
  transition: var(--transit-default);
  opacity: 0;
  pointer-events: none;
  z-index: 9998;
  @include mq(sp) {
    right: 10px;
    bottom: 10px;
    width: 220px;
    height: 80px;
  }
  &.is-show {
    opacity: 1;
    pointer-events: inherit;
  }
  &::after {
    content: '';
    position: absolute;
    right: 10px;
    bottom: 10px;
    @include rect(10);
    clip-path: polygon(100% 0, 100% 100%, 0 100%);
    background-color: var(--clr-wht);
    @include mq(sp) {
      right: 4px;
      bottom: 4px;
      @include rect(8);
    }
  }
  &:hover {
    background-color: var(--clr-red);
  }
  &:hover a {
    color: var(--clr-wht);
  }
}
.fix_cta a {
  display: flex;
  flex-direction: column;
  @include center-flex;
  height: 100%;
  font-family: var(--font-jp-r);
  font-size: rem(16);
  @include lh(16,28);
  letter-spacing: .075em;
  color: var(--clr-wht);
  @include mq(sp) {
    font-size: sprem(11);
    letter-spacing: .075em;
    @include lh(11,16);
  }
}
.fix_cta a .font-en {
  font-size: rem(32);
  line-height: .75;
  letter-spacing: .075em;
  margin-bottom: 16px;
  @include mq(sp) {
    font-size: sprem(20);
    letter-spacing: .075em;
    margin-bottom: 8px;
  }
}


/* section_contact
**************************************** */
.section_contact {
  background-color: var(--clr-br);
  @include mq(sp, min, ps) {
    background-image: url(/wp/wp-content/themes/standard_sass/images/common/cta_img.png);
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: right center;
  }
  @include mq(sp) {
    background-image: url(/wp/wp-content/themes/standard_sass/images/common/cta_img_sp.jpg);
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: center bottom;
  }

}
.section_contact-box {
  clip-path: polygon(0 0, calc(100% - 475px) 0%, 100% 100%, 0% 100%);
  background-color: var(--clr-br);
  @include mq(sp, min, ps) {
    width: 1180px;
    @include p-tb(120px,80px);
    padding-left: vw(160);
  }
  @include mq(1400) {
    width: 980px;
  }
  @include mq(sp) {
    padding: spvw(64) 7% spvw(300);
    width: 100%;
    clip-path: polygon(0 0, 100% 0, 100% calc(100% - spvw(192)), 0% calc(100% - spvw(248)));
  }
}
.contact_ttl-en {
  color: var(--clr-wht);
  font-family: var(--font-en);
  font-size: rem(80);
  line-height: .75;
  margin-bottom: 48px;
  @include mq(sp) {
    font-size: sprem(46);
    margin-bottom: 24px;
  }
}
.contact_ttl {
  color: var(--clr-wht);
  font-size: rem(40);
  line-height: 1;
  margin-bottom: 120px;
  @include mq(1400) {
    margin-bottom: 64px;
  }
  @include mq(sp) {
    font-size: sprem(22);
    margin-bottom: 40px;
  }
}
.contact_box {
  width: 750px;
  height: 88px;
  @include mq(1400) {
    flex-direction: column;
    width: 450px;
    height: auto;
  }
  @include mq(sp) {
    width: 100%;
    gap: 8px;
  }
}
.contact_box > div {
  width: calc((100% - 36px) / 2);
  height: 100%;
  @include mq(1400) {
    width: 100%;
    height: 80px;
  }
}
.contact_box .cta_tel {
  display: flex;
  @include center-flex;
  border: 1px solid var(--clr-wht);
}
.contact_box .cta_tel a {
  color: var(--clr-wht);
}
.contact_link a {
  height: 100%;
  gap: 16px;
  color: var(--clr-wht);
  font-size: rem(20);
  background-color: var(--clr-dbr);
  &:hover {
    color: var(--clr-wht);
    background-color: var(--clr-red);
  }
}
.contact_link a svg {
  fill: var(--clr-wht);
}