@use '../abstracts' as * ;

/* ##############################################################################

    FOOTER

############################################################################## */

/* footer_top
********************************************** */
  .footer_top {
    padding: 64px vw(160);
    @include mq(1400) {
      padding: 64px vw(48);
    }
    @include mq(sp) {
      gap: 24px;
      padding: spvw(48) 7% spvw(24);
    }
  }
  .footer_company {
    @include mq(sp) {
      gap: 24px;
    }
  }
  .footer_logo {
    margin-right: 24px;
    @include mq(sp) {
      margin-right: 16px;
      width: 96px;
    }
  }
  .footer_info p {
    font-size: rem(15);
    @include mq(sp) {
      font-size: sprem(13);
    }
  }

  /* ---sns_area --- */
  .sns--list {
    margin-top: 8px;
    @include mq(sp) {
      justify-content: flex-start;
    }
  }
  .sns--list li:not(:last-child) {
    margin-right: 16px;
    @include mq(sp) {
      margin-right: 8px;
    }
  }
  .sns--list a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    @include mq(sp) {
      width: 16px;
      height: 16px;
    }
  }
  .sns--list a img {
    width: 100%;
    height: 100%;
  }
  .sns--list a:hover {
    opacity: .7;
  }

  /* ---fnav --- */
  .fnav--menu {
    @include mq(sp) {
      width: 80%;
      @include auto-margin;
    }
  }
  .fnav--menu ul {
    @include mq(sp) {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }
  }
  .fnav--menu li {
    @include mq(sp) {
      width: calc((100% - 16px) / 2);
    }
  }
  .fnav--menu li.menu-item-has-children {
    width: 100%;
  }
  .fnav--menu a {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-right: 56px;
    margin-bottom: 8px;
    &:hover {
      color: var(--clr-main);
    }
    @include mq(sp) {
      position: relative;
      padding: .75em 8px .75em;
      margin-bottom: 0;
    }
  }

  .fnav--menu .sub-menu {
    @include mq(sp) {
      padding-left: 8px;
    }
  }
  .fnav--menu .sub-menu li {
    width: 100%;
  }
  .fnav--menu .sub-menu a {
    position: relative;
    padding-left: 32px;
    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      width: 20px;
      height: 1px;
      background-color: var(--clr-blk);
    }
  }


/* footer_btm
*************************************************** */
  .footer_btm {
    padding: 8px 0;
  }
  .pbl a {
    opacity: .5;
    &:hover {
      opacity: 1;
    }
  }
  .copyright {
    color: var(--clr-gry2);
    font-size: rem(12);
    @include mq(sp, min, ps) {
      margin-right: 16px;
    }
  }
  @include mq(sp) {
    .copyright {
      margin-right: 1em;
    }
  }
