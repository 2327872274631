@use '../abstracts' as *;

/* lps_parts--button
********************************************** */
.lps_parts--button .btn:first-child { margin-left: auto; }
.lps_parts--button .btn:last-child {
  margin-right: auto;
  @include mq(sp) {
    margin-bottom: 0;
  }
}

@include mq(sp, min, ps) {
  .lps_parts--column.column-2 + .lps_parts--button,
  .lps_parts--column.column-3 + .lps_parts--button,
  .lps_parts--column.column-4 + .lps_parts--button { margin-top: 0; }
}

@include mq(sp) {
  .lps_parts--button .flex-col3.flex-sp-block {
    flex-direction: row;
  }
  .lps_parts--button .flex-col3 .btn {
    width: 48.5%;
    margin-left: 0;
    margin-right: 0;
  }
  .lps_parts--button .flex-col3 .btn a {
    padding: 10px 18px;
    font-size: sprem(15);
    &::before {
      width: 20px;
    }
  }
}