@use '../abstracts' as *;

/* contact
********************************************** */
.lps_sec.contact {
  padding-bottom: 64px;
}
.contact .cta_tel {
  width: 100%;
  max-width: 400px;
  @include auto-margin;
}
.contact .cta_tel a {
  padding: 24px 40px;
  .contact .cta_tel a {
    display: block;
    padding: 16px;
  }
}